import { createAsyncThunk } from '@reduxjs/toolkit';
import QuickDisplayService from '@/services/QuickDisplayService';
import InstagramService from '@/services/InstagramService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`InstagramPost/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

export const getInstagramPropertyData = handler(
  'getInstagramPropertyData',
  QuickDisplayService.getQuickPropData
);

export const getInstagramDevelopmentData = handler(
  'getInstagramDevelopmentData',
  QuickDisplayService.getQuickDevData
);

export const postInstagramPublication = handler(
  'postInstagramPublication',
  InstagramService.postPublication
);

export const getIsUserLoggedOnInstagram = handler(
  'getIsUserLoggedOnInstagram',
  InstagramService.getIsUserLogged
);
