/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import MessengerService from '@/services/MessengerService';
import SenderService from '@/services/SenderService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`chat/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

export const setMessengerUserDataAsync = handler(
  'setMessengerUserDataAsync',
  MessengerService.getUserData
);

export const getAgentsChat = handler(
  'getAgentsChat',
  MessengerService.getAgentsChat
);

export const getDuplicateContacts = handler(
  'getDuplicateContacts',
  MessengerService.duplicateContacts
);

export const messengerManager = handler(
  'messengerManager',
  MessengerService.messengerManager
);

export const getLeadsStatus = handler(
  'getLeadsStatus',
  MessengerService.getLeadsStatus
);

export const getContact = handler('getContact', MessengerService.getContact);

export const changeLeadStatus = handler(
  'changeLeadStatus',
  MessengerService.changeLeadStatus
);

export const getPropertiesToShare = handler(
  'getPropertiesToShare',
  MessengerService.getPropertiesToShare
);

export const getQuickResponses = handler(
  'getQuickResponses',
  SenderService.getQuickResponses
);

export const getDevelopmentsToShare = handler(
  'getDevelopmentsToShare',
  MessengerService.getDevelopmentsToShare
);
