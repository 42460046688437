/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */

export function normalizeWebsiteData(data) {
  // TODO backend

  const showAssociations =
    data?.web?.settings?.design?.social?.show_associations || {};

  if (!Array.isArray(showAssociations.info)) {
    showAssociations.info = [];
  } else {
    showAssociations.info = showAssociations.info.filter((item) => item !== '');
  }

  const locationMapMapping = {
    'false-false': 'noMap',
    'false-true': 'approximateArea',
    'true-false': 'realTitle',
  };

  const map = data?.web?.settings?.details?.map;
  const openstreetmap = data?.web?.settings?.details?.openstreetmap;
  const locationMapKey = `${map}-${openstreetmap}`;
  const locationMap = locationMapMapping[locationMapKey] || 'noMap';

  // TODO This will be changed
  const templateKeyMapping = {
    1: 'premium',
    2: 'premium',
    3: 'premium',
    4: 'traditional',
    5: 'traditional',
    6: 'traditional',
    7: 'icons',
    8: 'simplified',
    9: 'none',
    10: 'autocomplete_with_video',
    11: 'autocomplete_with_image',
    12: 'traditional',
  };

  const selectedTemplateId = data?.template?.id || 1;

  const propertyTypesMapping = {
    premium: data?.web?.settings?.search?.premium?.property_types,
    traditional: data?.web?.settings?.search?.traditional?.property_types,
    icons: data?.web?.settings?.search?.icons?.property_types,
    simplified: data?.web?.settings?.search?.simplified?.property_types,
    none: data?.web?.settings?.search?.none?.property_types,
    autocomplete_with_video:
      data?.web?.settings?.search?.autocomplete_with_video?.property_types,
    autocomplete_with_image:
      data?.web?.settings?.search?.autocomplete_with_image?.property_types,
  };

  const filterTypeKey = templateKeyMapping[selectedTemplateId];
  const selectedPropertyTypes = propertyTypesMapping[filterTypeKey];

  let selectedFilterType =
    filterTypeKey === 'premium'
      ? data?.web?.settings?.search?.premium?.filters
      : data?.web?.settings?.search?.traditional?.filter;

  if (!Array.isArray(selectedFilterType)) {
    selectedFilterType = [selectedFilterType];
  }

  const searchTitleMapping = {
    premium: data?.web?.settings?.search?.premium?.text,
    traditional: data?.web?.settings?.search?.traditional?.text,
    icons: data?.web?.settings?.search?.icons?.text,
    simplified: data?.web?.settings?.search?.simplified?.text,
    none: data?.web?.settings?.search?.none?.text,
    autocomplete_with_video:
      data?.web?.settings?.search?.autocomplete_with_video?.text,
    autocomplete_with_image:
      data?.web?.settings?.search?.autocomplete_with_image?.text,
  };

  const searchTitle = searchTitleMapping[filterTypeKey];

  const searchTypeMapping = {
    premium: data?.web?.settings?.search?.premium?.states,
    traditional: data?.web?.settings?.search?.traditional?.states,
    icons: data?.web?.settings?.search?.icons?.states,
    simplified: data?.web?.settings?.search?.simplified?.states,
    none: data?.web?.settings?.search?.none?.states,
    autocomplete_with_video:
      data?.web?.settings?.search?.autocomplete_with_video?.states,
    autocomplete_with_image:
      data?.web?.settings?.search?.autocomplete_with_image?.states,
  };

  const selectedLocations = searchTypeMapping[filterTypeKey];

  const locationBannerMapping = {
    premium: data?.web?.settings?.search?.premium?.location,
    traditional: data?.web?.settings?.search?.traditional?.location,
    icons: data?.web?.settings?.search?.icons?.location,
    simplified: data?.web?.settings?.search?.simplified?.location,
    none: data?.web?.settings?.search?.none?.location,
    autocomplete_with_video:
      data?.web?.settings?.search?.autocomplete_with_video?.location,
    autocomplete_with_image:
      data?.web?.settings?.search?.autocomplete_with_image?.location,
  };

  const selectedOperationsMapping = {
    premium: data?.web?.settings?.search?.premium?.operations,
    traditional: data?.web?.settings?.search?.traditional?.operations,
    icons: data?.web?.settings?.search?.icons?.operations,
    simplified: data?.web?.settings?.search?.simplified?.operations,
    none: data?.web?.settings?.search?.none?.operations,
    autocomplete_with_video:
      data?.web?.settings?.search?.autocomplete_with_video?.operations,
    autocomplete_with_image:
      data?.web?.settings?.search?.autocomplete_with_image?.operations,
  };

  const selectedOperations = selectedOperationsMapping[filterTypeKey];

  const searchByReferenceCheckboxMapping = {
    premium: data?.web?.settings?.search?.premium?.byReferenceCode,
    traditional: data?.web?.settings?.search?.traditional?.byReferenceCode,
    icons: data?.web?.settings?.search?.icons?.byReferenceCode,
    simplified: data?.web?.settings?.search?.simplified?.byReferenceCode,
    none: data?.web?.settings?.search?.none?.byReferenceCode,
    autocomplete_with_video:
      data?.web?.settings?.search?.autocomplete_with_video?.byReferenceCode,
    autocomplete_with_image:
      data?.web?.settings?.search?.autocomplete_with_image?.byReferenceCode,
  };

  const searchByReferenceCheckbox =
    searchByReferenceCheckboxMapping[filterTypeKey];

  const locationBanner = locationBannerMapping[filterTypeKey];

  const videoIdYouTube =
    selectedTemplateId === 2
      ? data?.web?.settings?.search?.premium?.video_id
      : selectedTemplateId === 10
      ? data?.web?.settings?.search?.autocomplete_with_video?.video_id
      : undefined;

  // TODO backend
  const defaultPalette = ['#ffffff', '#f7f7f7', '#ff5a5e', '#25697e'];
  const selectedPalette =
    data?.web?.settings?.design?.palette?.selected || 'number1';
  const manualColorPalette = data?.web?.settings?.design?.palette?.manual;
  const isDefaultPalette =
    !manualColorPalette ||
    manualColorPalette.length === 0 ||
    JSON.stringify(manualColorPalette) ===
      JSON.stringify(['#ffffff', '#ffffff', '#a2ce3d', '#ffffff']);

  const validatedManualColorPalette = isDefaultPalette
    ? defaultPalette
    : manualColorPalette;
  const selectedColors =
    isDefaultPalette && selectedPalette === 'manual'
      ? defaultPalette
      : data?.web?.settings?.design?.palette?.colors;

  return {
    planUpgradePending: data?.web?.settings?.site_generator,
    hasTemplatePremium: data?.web?.settings?.has_template_premium,
    associations: data?.web?.settings?.associations,
    webDomain: data?.web?.domain,
    webTempDomain: data?.web?.temp_domain,
    webId: data?.web?.id,
    selectedTemplateId: data?.template?.id || 1,
    isPremiumTemplate: data?.web?.settings?.search?.selected === 'premium',
    logo: data?.web?.logo,
    favicon: data?.web?.favicon,
    language: data?.web?.settings?.design?.lang,
    menuType: data?.web?.settings?.design?.menu,
    searchTitle,
    locationBanner,
    selectedPropertyTypes,
    selectedOperations,
    selectedFilterType,
    selectedLocations,
    searchByReferenceCheckbox,
    developmentsGrid: data?.web?.settings?.design?.starred_development_grid,
    bannerProperty: data?.web?.settings?.design?.banner,
    backgroundImages: {
      backgroundImage1: data?.web?.background1_image,
      backgroundImage2: data?.web?.background2_image,
      backgroundImage3: data?.web?.background3_image,
      backgroundImage4: data?.web?.background4_image,
    },
    sectionImages: {
      section1_image: data?.web?.section1_image,
      section2_image: data?.web?.section2_image,
      section3_image: data?.web?.section3_image,
      contact_image: data?.web?.contact_image,
      quotation_image: data?.web?.quotation_image,
    },
    branches: data?.branches,
    section1name: data?.web?.sections?.section1?.name,
    sectionsGlobal: data?.web?.settings?.sections,
    stretchImageCheckbox: data?.web?.settings?.design?.use_stretch_wide,
    withTwoImagesCheckbox: data?.web?.settings?.design?.banner_two_images,
    thirdColorCheckbox: data?.web?.settings?.design?.design_banner_transparency,
    contactCheckbox:
      data?.web?.settings?.design?.social?.show_home_contact?.enabled,
    appraisalsCheckbox:
      data?.web?.settings?.design?.social?.show_home_quotation?.enabled,
    footerPalette: data?.web?.settings?.design?.footer_palette,
    footerType:
      data?.web?.settings?.design?.footer_palette?.selected || 'dark_footer',
    footerCustomInfo: data?.web?.settings?.design?.social?.custom_footer?.info,
    videoIdYouTube,
    homeSlogan: data?.web?.settings?.design?.social?.home?.info,
    footerCustomColor:
      data?.web?.settings?.design?.footer_palette?.bg || '#0099ff',
    showAssociations,
    phone: data?.web?.settings?.design?.social?.phone?.info,
    address: data?.web?.settings?.design?.social?.address?.info,
    wa: data?.web?.settings?.design?.social?.wa?.info,
    email: data?.web?.settings?.design?.social?.email?.info,
    afipqr: data?.web?.settings?.design?.social?.afipqr?.info,
    socialNetworkLinks: {
      facebook: data?.web?.settings?.design?.social?.fb?.info,
      instagram: data?.web?.settings?.design?.social?.ig?.info,
      x: data?.web?.settings?.design?.social?.tw?.info,
      linkedin: data?.web?.settings?.design?.social?.linkedin?.info,
      tiktok: data?.web?.settings?.design?.social?.tt?.info,
      youtube: data?.web?.settings?.design?.social?.yt?.info,
    },
    whatsappPluggin: data?.web?.settings?.design?.show_whatsapp?.enabled,
    googleTranslatePluggin: {
      langsSelected:
        data?.web?.settings?.design?.show_google_translate?.langs_selected,
      enabled: data?.web?.settings?.design?.show_google_translate?.enabled,
      widgetPosition:
        data?.web?.settings?.design?.show_google_translate?.widget_position,
    },
    aliasDomains: data?.alias,
    metaDescription: data?.web?.settings?.seo?.description,
    metaKeywords: data?.web?.settings?.seo?.keywords,
    excludeGoogleSearchCheckbox: data?.web?.settings?.seo?.no_follow,
    robotsTxtConfig: data?.web?.settings?.seo?.robots,
    modalIntegrations: {
      google_analytics_4:
        data?.web?.settings?.design?.social?.google_analytics_4,
      google_tag_manager:
        data?.web?.settings?.design?.social?.google_tag_manager,
      fb_pixel: data?.web?.settings?.design?.social?.fb_pixel,
      gmaps_apikey: {
        info: data?.web?.settings?.details?.gmaps_apikey,
        enabled: data?.web?.settings?.design?.social?.gmaps_apikey?.enabled,
      },
      chaterix: {
        enabled: !!data?.web?.settings?.design?.chat?.chaterix,
      },
      cliengo: {
        enabled: !!data?.web?.settings?.design?.chat?.cliengo,
      },
    },
    chaterixData: data?.chaterix,
    cliengoData: data?.cliengo,
    showServicesCheckbox: data?.web?.settings?.details?.services,
    showRoomsCheckbox: data?.web?.settings?.details?.rooms,
    showAddionalsCheckbox: data?.web?.settings?.details?.additionals,
    showBranchInfoCheckbox: data?.web?.settings?.details?.branch,
    map: data?.web?.settings?.details?.map,
    openstreetmap: data?.web?.settings?.details?.openstreetmap,
    locationMap,
    showPropertyInGoogleStreetView: data?.web?.settings?.details?.streetview,
    showProducerInfoCheckbox: data?.web?.settings?.details?.producer,
    showSimilarPropertiesCheckbox: data?.web?.settings?.details?.similar,
    showPopupVideoCheckbox: data?.web?.settings?.details?.popup_video,
    attributes: data?.web?.settings?.results?.attributes,
    tagGroups: data?.web?.settings?.results?.tag_groups,
    selectedColors,
    manualColorPalette: validatedManualColorPalette,
    selectedPalette,
    showingTitle: data?.web?.settings?.results?.card_title || 'pub-title',
    showingAddress: data?.web?.settings?.results?.address,
    styleFormat: data?.web?.settings?.results?.style,
    showNetworkProperties: data?.web?.settings?.results?.network,
    networks: data?.networks,
    hiddenFilters: data?.web?.settings?.results?.hidden_filters,
    selectedDivisions: data?.web?.settings?.search?.traditional?.divisions,
    privacyPolicy: {
      enabled:
        data?.web?.settings?.design?.social?.privacy_form?.enabled || false,
      companyName:
        data?.web?.settings?.design?.social?.privacy_form?.company || null,
      companyEmail:
        data?.web?.settings?.design?.social?.privacy_form?.email || null,
    },
  };
}
