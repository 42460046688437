const DictionaryNamePage = {
  '/properties/propertyimporter': 'Propertyimporter',
  '/properties/propertyimporter/importreport': 'Importreport',
  '/contacts/duplicated': 'Duplicatedcontacts',
  '/instagram/post': 'Instagrampost',
  '/company/properties': 'PropertiesConfiguration',
  '/webmanager': 'WebSites',
  '/webmanager/templatelist': 'TemplateList',
  '/webmanager/templateconfig': 'TemplateConfiguration',
};

export default DictionaryNamePage;
