const initialState = {
  loading: false,
  intercomInitialized: false,
  loadingFetchWebsiteData: false,
  loadingCreateWebsite: false,
  loadingFetchTemplates: false,
  loadingDeleteWebsite: false,
  loadingFetchWebsites: false,
  loadingFetchAssociations: false,
  loadingGetLocationsQuickSearch: false,
  loadingPublishWebsite: false,
  loadingSetAsMainWebsite: false,
  loadingSaveAliasWebsite: false,
  loadingRemoveAliasWebsite: false,
  loadingFetchFavoriteSearches: false,
  loadingGetAllowedTagGroups: false,
  loadingPutUserModals: false,
  loadingUploadMultimedia: false,
  userModals: {},
  filteredLocations: [],
  selectedTemplateId: 4,
  isPremiumTemplate: false,
  showPremiumBanner: true,
  planUpgradePending: false,
  error: null,
  templatesData: null,
  hasTemplatePremium: false,
  websites: [],
  websitesCanCreate: true,
  webDomain: '',
  webTempDomain: '',
  webId: '',
  logo: '',
  favicon: '',
  language: '',
  menuType: '',
  footerPalette: {},
  footerType: '',
  footerCustomColor: '',
  footerCustomInfo: '',
  phone: '',
  address: '',
  wa: '',
  email: '',
  afipqr: '',
  socialNetworkLinks: {},
  googleTranslatePluggin: {
    langsSelected: [],
    enabled: false,
    widgetPosition: 'show_on_menu',
  },
  whatsappPluggin: false,
  selectedColors: [],
  manualColorPalette: [],
  selectedPalette: '',
  backgroundImages: {
    backgroundImage1: '',
    backgroundImage2: '',
    backgroundImage3: '',
    backgroundImage4: '',
  },
  sectionImages: {
    sectionImage1: '',
    sectionImage2: '',
    sectionImage3: '',
  },
  section1name: '',
  sections: {},
  favoriteSearches: [],
  sectionsGlobal: {},
  branches: [],
  developmentsGrid: false,
  contactCheckbox: false,
  appraisalsCheckbox: false,
  searchByReferenceCheckbox: false,
  selectedDivisions: [],
  videoIdYouTube: '',
  bannerProperty: 'starred-properties',
  thirdColorCheckbox: false,
  stretchImageCheckbox: false,
  withTwoImagesCheckbox: false,
  showingTitle: 'pub-title',
  showingAddress: 'real',
  styleFormat: 'grid',
  homeSlogan: '',
  showNetworkProperties: '',
  networks: ['test'],
  selectedAllowedFilters: [],
  hiddenFilters: [],
  selectedPropertyTypes: [],
  selectedOperations: [],
  selectedFilterType: [],
  selectedLocations: [],
  locations: [],
  propertyTypes: [],
  showServicesCheckbox: false,
  showRoomsCheckbox: false,
  showAddionalsCheckbox: false,
  showProducerInfoCheckbox: false,
  showBranchInfoCheckbox: false,
  locationMap: 'noMap',
  showPropertyInGoogleStreetView: false,
  showSimilarPropertiesCheckbox: false,
  showPopupVideoCheckbox: false,
  selectedShowingAttributes: [],
  selectedShowingTagGroups: [],
  propertyAttributes: [],
  allowedTagGroups: {},
  tagGroups: {},
  selectedPropertyType: null,
  selectedAttributeType: null,
  selectedAttribute: null,
  attributes: {},
  metaDescription: '',
  metaKeywords: '',
  excludeGoogleSearchCheckbox: false,
  robotsTxtConfig: '',
  aliasDomains: [],
  searchTitle: 'Find Your Property 5',
  locationBanner: 'left',
  modalIntegrations: {
    gmaps_apikey: {
      info: '',
      enabled: false,
    },
    googleAnalytics: {
      info: '',
      enabled: false,
    },
  },
  showAssociations: {
    info: [],
    enabled: true,
  },
  associations: [],
  privacyPolicy: {
    enabled: false,
    companyName: null,
    companyEmail: null,
  },
};

export default initialState;
