import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import WebsitesService from '@/services/WebsitesService';
import initialState from './initialState';
import { normalizeWebsiteData } from './utils';
import TemplatesService from '@/services/TemplatesService';
import LocationsService from '@/services/LocationsService';
import PropertiesService from '@/services/PropertiesService';
import FavoritesService from '@/services/FavoritesService';
import CompanyService from '@/services/CompanyService';
import UserService from '@/services/UserService';
import { deleteFavSearch } from '../favorites/services';
import PropertyConfiguratorService from '@/services/PropertyConfiguratorService';

export const fetchWebsiteData = createAsyncThunk(
  'template-config-data/fetchWebsiteData',
  async (websiteId) => {
    const response = await WebsitesService.getWebsiteData(websiteId);
    return response;
  }
);

export const fetchWebsites = createAsyncThunk(
  'template-config-data/fetchWebsites',
  async () => {
    const response = await WebsitesService.getWebsites();
    return response;
  }
);

export const fetchWebsiteAssociations = createAsyncThunk(
  'template-config-data/fetchWebsiteAssociations',
  async () => {
    const response = await WebsitesService.getWebsiteAssociations();
    return response;
  }
);

export const createWebsite = createAsyncThunk(
  'template-config-data/createWebsite',
  async (name) => {
    const response = await WebsitesService.createWebsite(name);
    return response;
  }
);

export const deleteWebsite = createAsyncThunk(
  'template-config-data/deleteWebsite',
  async (websiteId) => {
    const response = await WebsitesService.deleteWebsite(websiteId);
    return response;
  }
);

export const updateWebsite = createAsyncThunk(
  'template-config-data/updateWebsite',
  async ({ websiteId, requestBody }) => {
    const response = await WebsitesService.updateWebsite(
      websiteId,
      requestBody
    );
    return response;
  }
);

export const fetchTemplates = createAsyncThunk(
  'templates/fetchTemplates',
  async () => {
    const response = await TemplatesService.getTemplates();
    return response;
  }
);

export const uploadImage = createAsyncThunk(
  'template-config-data/uploadImage',
  async ({ websiteId, imageKey, imageFile }) => {
    const response = await WebsitesService.uploadImage(
      websiteId,
      imageKey,
      imageFile
    );
    return response;
  }
);

export const uploadMultimedia = createAsyncThunk(
  'template-config-data/uploadMultimedia',
  async ({
    name,
    websiteId,
    section = 'ASSOCIATIONS',
    imageFile,
    mediaType = 'company',
  }) => {
    const response = await WebsitesService.uploadMultimedia(
      name,
      websiteId,
      section,
      imageFile,
      mediaType
    );

    return response;
  }
);

export const deleteImage = createAsyncThunk(
  'template-config-data/deleteImage',
  async ({ websiteId, requestBody }) => {
    const response = await WebsitesService.deleteImage(websiteId, requestBody);
    return response;
  }
);

export const getLocationsQuickSearch = createAsyncThunk(
  'template-config-data/getLocationsQuickSearch',
  async (query) => {
    const response = await LocationsService.getLocationsQuickSearch(query);
    return response;
  }
);

export const getLocationsObjList = createAsyncThunk(
  'template-config-data/getLocationsObjList',
  async (data) => {
    const response = await LocationsService.getLocationsObjList(data);
    return response;
  }
);

export const getLocationsStates = createAsyncThunk(
  'template-config-data/getLocationsStates',
  async (companyCountry = true) => {
    const response = await LocationsService.getLocationsStates(companyCountry);
    return response;
  }
);

export const fetchDefaultValues = createAsyncThunk(
  'template-config-data/fetchDefaultValues',
  async () => {
    const response = await PropertiesService.getDefaultValues();
    return response;
  }
);

export const publishWebsite = createAsyncThunk(
  'template-config-data/publishWebsite',
  async (websiteId) => {
    const response = await WebsitesService.publishWebsite(websiteId);
    return response;
  }
);

export const setAsMainWebsite = createAsyncThunk(
  'template-config-data/setAsMainWebsite',
  async (websiteId) => {
    const response = await WebsitesService.setAsMainWebsite(websiteId);
    return response;
  }
);

export const saveAliasWebsite = createAsyncThunk(
  'template-config-data/saveAliasWebsite',
  async ({ websiteId, aliasText }) => {
    const response = await WebsitesService.saveAliasWebsite(
      websiteId,
      aliasText
    );
    return { aliasText, objectId: response.object_id };
  }
);

export const removeAliasWebsite = createAsyncThunk(
  'template-config-data/removeAliasWebsite',
  async ({ websiteId, aliasId }) => {
    const response = await WebsitesService.removeAliasWebsite(
      websiteId,
      aliasId
    );
    return { aliasId, response };
  }
);

export const fetchFavoriteSearches = createAsyncThunk(
  'template-config-data/fetchFavoriteSearches',
  async () => {
    const response = await FavoritesService.getFavSearchs();
    const res = response.searches.map((r) => ({
      id: r.id,
      name: r.name,
      search_params: r.search_params,
      value: r.id,
    }));
    return res;
  }
);

export const getAllowedTagGroups = createAsyncThunk(
  'template-config-data/getAllowedTagGroups',
  async (propertyTypeId) => {
    const response = await PropertiesService.getAllowedTagGroups(
      propertyTypeId
    );
    return response;
  }
);

export const getPropertyAttributes = createAsyncThunk(
  'template-config-data/getPropertyAttributes',
  async () => {
    const response = await PropertiesService.getPropertyAttributes();
    return response;
  }
);

export const fetchIntercomData = createAsyncThunk(
  'template-config-data/fetchIntercomData',
  async () => {
    const response = await WebsitesService.getIntercomData();
    return response;
  }
);

export const putUserModals = createAsyncThunk(
  'template-config-data/putUserModals',
  async (userModals) => {
    const response = await UserService.putUserModals(userModals);
    return response;
  }
);

export const putUpgradePending = createAsyncThunk(
  'template-config-data/putUpgradePending',
  async (pendingUpgrade) => {
    const response = await CompanyService.putUpgradePending(pendingUpgrade);
    return response;
  }
);

export const fetchPropertyTypes = createAsyncThunk(
  'template-config-data/fetchPropertyTypes',
  async () => {
    const response = await PropertyConfiguratorService.fetchPropertyTypes();
    return response;
  }
);

export const TemplateConfigSlice = createSlice({
  name: 'template-config-data',
  initialState,
  reducers: {
    setInitialState(state, action) {
      return { ...state, ...action.payload };
    },
    setLogo(state, action) {
      state.logo = action.payload;
    },
    setFavicon(state, action) {
      state.favicon = action.payload;
    },
    setField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setArrayField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = [...value];
    },
    setInformation(state, action) {
      const key = Object.keys(action.payload)[0];
      state[key] = action.payload[key];
    },
    setSocialNetworkLink(state, action) {
      state.socialNetworkLinks[action.payload.network] = action.payload.link;
    },
    setBackgroundImages(state, action) {
      const { id, url } = action.payload;
      state.backgroundImages[id] = url;
    },
    setBackgroundImageAsNone(state, action) {
      const { id } = action.payload;
      state.backgroundImages[id] = null;
    },
    setSectionImages(state, action) {
      const { id, url } = action.payload;
      state.sectionImages[id] = url;
    },
    setSectionImageAsNone(state, action) {
      const { id } = action.payload;
      state.sectionImages[id] = null;
    },
    setBooleanField(state, action) {
      const field = action.payload;
      state[field] = !state[field];
    },
    setModalInfo: (state, action) => {
      const { modalId, info, enabled } = action.payload;
      if (!state.modalIntegrations[modalId]) {
        state.modalIntegrations[modalId] = {
          info: '',
          enabled: false,
        };
      }
      state.modalIntegrations[modalId].info = info;
      state.modalIntegrations[modalId].enabled = enabled;
    },
    setGoogleTranslatePluggin: (state, action) => {
      const { enabled, langsSelected, widgetPosition } = action.payload;
      if (enabled !== undefined) state.googleTranslatePluggin.enabled = enabled;
      if (langsSelected !== undefined)
        state.googleTranslatePluggin.langsSelected = langsSelected;
      if (widgetPosition !== undefined)
        state.googleTranslatePluggin.widgetPosition = widgetPosition;
    },
    setShowAssociations: (state, action) => {
      const association = action.payload;
      if (association) {
        const index = state.showAssociations.info.indexOf(association);
        if (index === -1) {
          state.showAssociations.info.push(association);
        } else {
          state.showAssociations.info.splice(index, 1);
        }
      }
    },
    addAssociation(state, action) {
      const { name, file } = action.payload;
      if (!state.associations['00']) {
        state.associations['00'] = [];
      }
      state.associations['00'].push({ name, file });
    },
    setPrivacyPolicy: (state, action) => {
      const { field, value } = action.payload;
      state.privacyPolicy[field] = value;
    },
    updateSection(state, action) {
      const { sectionId, updates } = action.payload;

      if (state.sectionsGlobal[sectionId]) {
        state.sectionsGlobal[sectionId] = {
          ...state.sectionsGlobal[sectionId],
          ...updates,
        };
      }
    },
    addTagToGroup(state, action) {
      const { groupId, tag } = action.payload;
      if (!state.tagGroups) {
        state.tagGroups = {};
      }
      if (state.tagGroups[groupId]) {
        state.tagGroups[groupId].push(tag);
      } else {
        state.tagGroups[groupId] = [tag];
      }
    },
    removeTagFromGroup(state, action) {
      const { groupId, tagId } = action.payload;
      if (state.tagGroups[groupId]) {
        state.tagGroups[groupId] = state.tagGroups[groupId].filter(
          (tag) => tag.id !== tagId
        );
      }
    },
    addAttributeToGroup(state, action) {
      const { propertyType, attribute } = action.payload;
      if (state.attributes[propertyType].length < 6) {
        state.attributes[propertyType].push({
          type: attribute.type,
          attr: attribute.attr,
          name: attribute.name,
        });
      }
    },
    removeAttributeFromGroup(state, action) {
      const { propertyType, attr } = action.payload;
      state.attributes[propertyType] = state.attributes[propertyType].filter(
        (attribute) => attribute.attr !== attr
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsiteData.pending, (state) => {
        state.loadingFetchWebsiteData = true;
      })
      .addCase(fetchWebsiteData.fulfilled, (state, action) => {
        const data = action.payload;
        const normalizedData = normalizeWebsiteData(data);
        state.error = null;
        state.loadingFetchWebsiteData = false;

        Object.assign(state, normalizedData);
      })
      .addCase(fetchWebsiteData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchWebsiteData = false;
      })
      .addCase(createWebsite.pending, (state) => {
        state.loadingCreateWebsite = true;
      })
      .addCase(createWebsite.fulfilled, (state, action) => {
        state.webId = action.payload.website_id;
        state.error = null;
        state.loadingCreateWebsite = false;
      })
      .addCase(createWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingCreateWebsite = false;
      })
      .addCase(deleteWebsite.pending, (state) => {
        state.loadingDeleteWebsite = true;
      })
      .addCase(deleteWebsite.fulfilled, (state, action) => {
        state.error = null;
        state.websites = state.websites.filter(
          (website) => website.id !== action.payload.id
        );
        state.loadingDeleteWebsite = false;
      })
      .addCase(deleteWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingDeleteWebsite = false;
      })
      .addCase(fetchWebsites.pending, (state) => {
        state.loadingFetchWebsites = true;
      })
      .addCase(fetchWebsites.fulfilled, (state, action) => {
        state.websites = action.payload.websites;
        state.websitesCanCreate = action.payload.websites_can_create;
        state.error = null;
        state.loadingFetchWebsites = false;
      })
      .addCase(fetchWebsites.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchWebsites = false;
      })
      .addCase(fetchTemplates.pending, (state) => {
        state.loadingFetchTemplates = true;
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.templatesData = action.payload;
        state.hasTemplatePremium = action.payload.has_template_premium;
        state.showPremiumBanner = action.payload.show_upgrade_website;
        state.planUpgradePending = action.payload.is_pending_upgrade;
        state.loadingFetchTemplates = false;
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchTemplates = false;
      })
      .addCase(uploadMultimedia.pending, (state) => {
        state.loadingUploadMultimedia = true;
      })
      .addCase(uploadMultimedia.fulfilled, (state) => {
        state.loadingUploadMultimedia = false;
        state.error = null;
      })
      .addCase(uploadMultimedia.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingUploadMultimedia = false;
      })
      .addCase(getLocationsQuickSearch.pending, (state) => {
        state.loadingGetLocationsQuickSearch = true;
      })
      .addCase(getLocationsQuickSearch.fulfilled, (state, action) => {
        state.filteredLocations = action.payload;
        state.loadingGetLocationsQuickSearch = false;
      })
      .addCase(getLocationsQuickSearch.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingGetLocationsQuickSearch = false;
      })
      .addCase(getLocationsObjList.pending, (state) => {
        state.loadingGetLocationsObjList = true;
      })
      .addCase(getLocationsObjList.fulfilled, (state, action) => {
        state.locationsObjList = action.payload;
        state.loadingGetLocationsObjList = false;
      })
      .addCase(getLocationsObjList.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingGetLocationsObjList = false;
      })
      .addCase(getLocationsStates.pending, (state) => {
        state.loadingGetLocationsStates = true;
      })
      .addCase(getLocationsStates.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.loadingGetLocationsStates = false;
      })
      .addCase(getLocationsStates.rejected, (state, action) => {
        state.errorLocationsStates = action.error.message;
        state.loadingGetLocationsStates = false;
      })
      .addCase(fetchPropertyTypes.pending, (state) => {
        state.loadingFetchPropertyTypes = true;
      })
      .addCase(fetchPropertyTypes.fulfilled, (state, action) => {
        state.propertyTypes = action.payload;
        state.loadingFetchPropertyTypes = false;
      })
      .addCase(fetchPropertyTypes.rejected, (state, action) => {
        state.errorFetchPropertyTypes = action.error.message;
        state.loadingFetchPropertyTypes = false;
      })
      .addCase(fetchDefaultValues.pending, (state) => {
        state.loadingFetchDefaultValues = true;
      })
      .addCase(fetchDefaultValues.fulfilled, (state, action) => {
        state.defaultValues = action.payload;
        state.loadingFetchDefaultValues = false;
      })
      .addCase(fetchDefaultValues.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchDefaultValues = false;
      })
      .addCase(publishWebsite.pending, (state) => {
        state.loadingPublishWebsite = true;
      })
      .addCase(publishWebsite.fulfilled, (state) => {
        state.loadingPublishWebsite = false;
        state.error = null;
      })
      .addCase(publishWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingPublishWebsite = false;
      })
      .addCase(setAsMainWebsite.pending, (state) => {
        state.loadingSetAsMainWebsite = true;
      })
      .addCase(setAsMainWebsite.fulfilled, (state) => {
        state.error = null;
        state.loadingSetAsMainWebsite = false;
      })
      .addCase(setAsMainWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingSetAsMainWebsite = false;
      })
      .addCase(saveAliasWebsite.pending, (state) => {
        state.loadingSaveAliasWebsite = true;
      })
      .addCase(saveAliasWebsite.fulfilled, (state, action) => {
        const { aliasText, objectId } = action.payload;
        state.aliasDomains.push({ alias: aliasText, id: objectId });
        state.loadingSaveAliasWebsite = false;
        state.error = null;
      })
      .addCase(saveAliasWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingSaveAliasWebsite = false;
      })
      .addCase(removeAliasWebsite.pending, (state) => {
        state.loadingRemoveAliasWebsite = true;
      })
      .addCase(removeAliasWebsite.fulfilled, (state, action) => {
        state.aliasDomains = state.aliasDomains.filter(
          (alias) => alias.id !== action.payload.aliasId
        );
        state.loadingRemoveAliasWebsite = false;
        state.error = null;
      })
      .addCase(removeAliasWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingRemoveAliasWebsite = false;
      })
      .addCase(fetchFavoriteSearches.pending, (state) => {
        state.loadingFetchFavoriteSearches = true;
      })
      .addCase(fetchFavoriteSearches.fulfilled, (state, action) => {
        state.favoriteSearches = action.payload;
        state.loadingFetchFavoriteSearches = false;
        state.error = null;
      })
      .addCase(fetchFavoriteSearches.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchFavoriteSearches = false;
      })
      .addCase(getAllowedTagGroups.pending, (state) => {
        state.loadingGetAllowedTagGroups = true;
      })
      .addCase(getAllowedTagGroups.fulfilled, (state, action) => {
        state.allowedTagGroups = action.payload;
        state.loadingGetAllowedTagGroups = false;
      })
      .addCase(getAllowedTagGroups.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingGetAllowedTagGroups = false;
      })
      .addCase(getPropertyAttributes.pending, (state) => {
        state.loadingGetPropertyAttributes = true;
      })
      .addCase(getPropertyAttributes.fulfilled, (state, action) => {
        state.propertyAttributes = action.payload;
        state.loadingGetPropertyAttributes = false;
      })
      .addCase(getPropertyAttributes.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingGetPropertyAttributes = false;
      })
      .addCase(putUserModals.pending, (state) => {
        state.loadingPutUserModals = true;
      })
      .addCase(putUserModals.fulfilled, (state, action) => {
        state.userModals = action.payload;
        state.loadingPutUserModals = false;
        state.error = null;
      })
      .addCase(putUserModals.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingPutUserModals = false;
      })
      .addCase(deleteFavSearch.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.favoriteSearches = state.favoriteSearches.filter(
          (search) => search.id !== id
        );

        // TODO Backend
        for (let i = 1; i <= 5; i++) {
          const presetSearchKey = `preset_search${i}`;
          if (
            state.sectionsGlobal[presetSearchKey] &&
            Array.isArray(state.sectionsGlobal[presetSearchKey].search_id)
          ) {
            state.sectionsGlobal[presetSearchKey].search_id =
              state.sectionsGlobal[presetSearchKey].search_id.filter(
                (searchId) => searchId !== id
              );
          }
        }
      });
  },
});

export const {
  setInitialState,
  setLogo,
  setFavicon,
  setField,
  setArrayField,
  setInformation,
  setSocialNetworkLink,
  setBackgroundImages,
  setBackgroundImageAsNone,
  setSectionImages,
  setSectionImageAsNone,
  setBooleanField,
  setModalInfo,
  setGoogleTranslatePluggin,
  setShowAssociations,
  addAssociation,
  setPrivacyPolicy,
  updateSection,
  addTagToGroup,
  removeTagFromGroup,
  addAttributeToGroup,
  removeAttributeFromGroup,
} = TemplateConfigSlice.actions;

export const selectTemplateConfig = (state) => state.TemplateConfigSlice;

export default TemplateConfigSlice.reducer;
