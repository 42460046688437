import {
  MAX_CHARACTER_COUNT,
  MAX_HASHTAGS,
  MAX_MENTIONS,
  WARNING_THRESHOLD,
} from './constants';

export const getCountStatus = (text) => {
  if (text.length > MAX_CHARACTER_COUNT) {
    return 'error';
  }

  if (text.length >= WARNING_THRESHOLD) return 'warning';

  return 'default';
};

export const checkIfIsMentionsError = (text) => {
  const mentions = text.match(/@\w+/g);

  if (!mentions) return false;

  return mentions.length > MAX_MENTIONS;
};

export const checkIfIsHashtagsError = (text) => {
  const hashtags = text.match(/#\w+/g);

  if (!hashtags) return false;

  return hashtags.length > MAX_HASHTAGS;
};

export const getErrorTextKey = (
  countStatus,
  isMentionsError,
  isHashtagsError
) => {
  const isCountError = countStatus === 'error';

  const errorMap = {
    111: 'MentionsAndHashtagsAndCountError',
    110: 'MentionsAndHashtagsError',
    101: 'MentionsAndCountError',
    100: 'MentionsError',
    '011': 'HashtagsAndCountError',
    '010': 'HashtagsError',
    '001': 'CharactersError',
  };

  const key = `${+isMentionsError}${+isHashtagsError}${+isCountError}`;
  return errorMap[key] || 'DefaultTextareaError';
};
