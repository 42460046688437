import ApiService from '@/services/ApiService';

class InstagramService {
  static async postPublication(payload) {
    const composedURL = `/meta_platforms/instagram/publish/`;
    const response = await ApiService.post(composedURL, payload);
    return response;
  }

  static async getIsUserLogged() {
    const composedURL = `/meta_platforms/credential/verify/`;
    const response = await ApiService.post(composedURL);
    return response;
  }
}

export default InstagramService;
