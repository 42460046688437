const initialState = {
  isLogged: true,
  step: 1,
  canMoveToNextStep: false,
  previewIndex: 0,
  canVisualizeEntity: true,
  username: null,
  postLimitReached: false,
  entity: {
    images: null,
    items: null,
    checkedItems: [],
    loading: true,
    error: false,
    type: '',
    id: null,
  },
  description: {
    value: '',
    errorTextKey: '',
    showErrorBanner: false,
    error: false,
  },
  posting: {
    loading: false,
    error: false,
  },
};

export default initialState;
